html,
body,
#root {
  background-color: #000000;
}

canvas {
  width: 100% !important;
  height: auto;
}

.konvajs-content {
  width: initial !important;
  background-color: #11468f;
}

.feature-box li {
  list-style: none;
}

.image-gallery-thumbnails {
  padding-top: 25px !important;
}

.image-gallery-thumbnail img {
  border-radius: 5px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 2px solid #f2050b !important;
  border-radius: 5px !important;
}

.spacer-60 {
  padding-bottom: 60px;
}

.policy h4 {
  color: #F2050B;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 24px;
  padding-top: 40px;
  padding-bottom: 8px;
}

.policy p {
  font-size: 14px;
  line-height: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.policy p img {
  width : 100%;
}

.faq {
  padding-bottom: 48px;
}

.faq a, .policy a {
  color: #F2050B;
  text-decoration: underline;
}

.faq .MuiAccordion-root {
  border-bottom: 1px solid #F2050B;
  padding: 26px 0;
}

.faq .MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.faq .MuiAccordionSummary-root {
  padding: 0;
}

.faq .MuiAccordionDetails-root {
  padding: 8px 0px 16px;
}

.faq .MuiAccordionSummary-expandIconWrapper {
  color: #F2050B;
}

.faq .MuiAccordionSummary-expandIconWrapper svg{
  font-size: 2.5rem;
}
